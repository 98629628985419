<template>
  <div class="bannerWrap">
    <div class="container-full banner-content">
      <el-carousel class="carousel" height="560px" arrow="hover" :interval="2000" trigger="click">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.localimg" @click="bannerClick(item)">
        </el-carousel-item>
      </el-carousel>
      <!-- <div class="banner-usermenu">
        <index-banner-item/>
      </div>
      <div class="banner-left">
        <leftMenu/>
      </div> -->
      <div class="banner-usermenu">
        <leftMenu />
      </div>
      <div class="banner-left">
        <!-- <JoinKefu v-if="showPrefer"/> -->
        <index-banner-item />
      </div>
    </div>
  </div>
</template>

<script>
import IndexBannerItem from 'views/index/childComps/IndexBannerItem'
import leftMenu from 'views/index/childComps/leftMenu'
// import JoinKefu from './joinKefu.vue'
import { getAdsInfo } from 'network/api'

export default {
  components: { IndexBannerItem, leftMenu },
  data () {
    return {
      bannerList: [],
      showPrefer: false
    }
  },
  created () {
    getAdsInfo({ adstag: 'ads' }).then(res => {
      console.log('')
      this.bannerList = res.data.adslist
    })
    this.showPrefer = this.$configList[window.location.host] === '1459435534555418624'
  },
  methods: {
    bannerClick (item) {
      if (item.clickhref === 'kefu') {
        window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
      } else if (item.clickhref.indexOf('://') !== -1) {
        location.href = item.clickhref
      } else {
        this.$router.push(item.clickhref)
      }
      // switch (index) {
      //   case 0:
      //     window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
      //     break
      //   case 1:
      //     this.$router.push('/join')
      //     break
      //   case 2:
      //     this.$router.push('/account/InviteFriends')
      //     break
      // }
    }
  }
}
</script>

<style scoped src="styles/views/index/indexbanner.css">
</style>
