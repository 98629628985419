<template>
    <div class="container footer">
      <div class="footer-warning">
        <!-- <div>声明：本站产品均为商家辅助使用</div>
        <div>禁止使用于违法或侵害他人权益行为，若有立马封号处理，余额不退还，情节严重者，本站积极协助公安机关调查！</div> -->
        <div style="opacity: 0.3" v-if="domainList.indexOf(domain) !== -1">惠州市导一企业管理咨询服务有限公司</div>
        <div v-if="domain === 'www.daifaliwu.cn'" style="width: 100%;text-align: center;line-height: 60px;color: #999;">Copyright © 2021. 杭州奔特科技有限公司 <a style="color: #999;" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021025493号-2</a ></div>
        <div v-if="domain === 'www.haolidaifa.com'" style="width: 100%;text-align: center;line-height: 60px;color: #999;">Copyright © 2021. 杭州奔特科技有限公司 <a style="color: #999;" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021025493号-3</a ></div>
        <div v-if="domain === 'www.1688dxb.com'" style="width: 100%;text-align: center;line-height: 60px;color: #999;">Copyright © 2021. 郴州抖小保电子商务有限公司 <a style="color: #999;" href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2022012885号-1</a ></div>
        <div v-if="domain === 'lp.shjkk1323.com'" style="width: 100%;text-align: center;line-height: 60px;color: #999;">Copyright © 2021. 惠州市惠城区朋艺传媒工作室 <a style="color: #999;" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022101564号-1号</a ></div>
      </div>
      <div class="footer-link">
        <!-- <div>Copyright © 2021 {{$store.getters.getSiteInfo.name}} 版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index">{{$store.getters.getSiteInfo.beian}}</a></div> -->
      </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      domain: window.location.host,
      domainList: ['lp.daoyizx.top', 'lp2.daoyizx.top']
    }
  }
}
</script>

<style scoped src="styles/components/bottomfooter.css">
</style>
