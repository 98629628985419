<template>
  <div class="index">
    <nav-bar/>
    <div v-if="isMain">
      <index-banner/>
    </div>
    <div v-else>
      <ckList />
      <GoodsList />
    </div>
    <!-- <index-banner/> -->
    <!-- <hot-goods :goods_class="0"/>
    <hot-goods :goods_class="1"/>
    <hot-goods :goods_class="2"/>
    <hot-goods :goods_class="3"/> -->
    <!-- <invite/> -->
    <!-- <daifa-guide/> -->
<!--    <toast-dialog />-->
    <!-- <ImageCard /> -->
    <!-- <blankbg /> -->
    <Fastmail />
    <bottom-footer/>
    <back-top/>
    <!-- <div class="flexImageWrap" v-if="showToast && mineKey == '1459435534555418624'" @click="showToast = false">
      <img src="http://scs.4723bao.com/upload/matter/20230323/68f4a1f9064827e350110b29b8df526d.jpg" class="flexImage" style="width: 300px;height: 300px;"/>
      <i class="el-icon-circle-close fiexIcon"></i>
    </div> -->
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import IndexBanner from 'views/index/childComps/IndexBanner'
// import HotGoods from 'views/index/childComps/HotGoods'
import BottomFooter from 'components/bottomfooter/BottomFooter'
import BackTop from 'components/backtop/BackTop'
// import ToastDialog from 'components/indexToast/index'
// import ImageCard from './childComps/imageCard.vue'
// import blankbg from './childComps/blankbg.vue'
import Fastmail from './childComps/fastmail.vue'
import ckList from './childComps/ckList'
import GoodsList from './childComps/goodsList'

export default {
  data () {
    return {
      showToast: true,
      mineKey: '',
      isMain: this.isMain
    }
  },
  components: {
    NavBar,
    IndexBanner,
    // HotGoods,
    BottomFooter,
    BackTop,
    ckList,
    GoodsList,
    Fastmail
    // ImageCard,
    // blankbg
    // ToastDialog
  },
  mounted () {
    this.mineKey = this.$configList[window.location.host]
    window._agl && window._agl.push(['track', ['success', { t: 18 }]])
    // console.log(window.location.host, 'host')
    // console.log(navigator, '浏览器标识')
    // console.log(navigator.mediaDevices, '浏览器标识2')
  },
  methods: {
    getUUID () {
      var s = []
      var hexDigits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRETUVWXYZ@#$%¥&()'
      for (var i = 0; i < 25; i++) {
        s[i] = hexDigits[Math.floor(Math.random() * 0x40)]
      }
      s[12] = s[20] = '_'
      var uuid = s.join('')
      return uuid
    }

    // getUUID (domain) {
    //   var canvas = document.createElement('canvas')
    //   var ctx = canvas.getContext('2d')
    //   var txt = domain
    //   ctx.textBaseline = 'top'
    //   ctx.font = '14px "Arial"'
    //   ctx.textBaseline = 'tencent'
    //   ctx.fillStyle = '#f60'
    //   ctx.fillRect(125, 1, 62, 20)
    //   ctx.fillStyle = '#069'
    //   ctx.fillText(txt, 2, 15)
    //   ctx.fillStyle = 'rgba(102, 204, 0, 0.7)'
    //   ctx.fillText(txt, 4, 17)

    //   var b64 = canvas.toDataURL().replace('data:image/png;base64,', '')
    //   var bin = atob(b64)
    //   var crc = this.bin2hex(bin.slice(-16, -12))
    //   return crc
    // },
    // bin2hex (s) {
    //   let o = ''
    //   let n = ''
    //   s += ''
    //   for (let i = 0; i < s.length; i++) {
    //     n = s.charCodeAt(i).toString(16)
    //     o += n.length < 2 ? '0' + n : n
    //   }
    //   return o
    // }
  }
}
</script>
<style scoped>
.flexImageWrap{
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, .5);
}
.flexImage{
  width: 300px;
  height: 300px;
  margin-bottom: 200px;
}
.fiexIcon{
    position: fixed;
    /* left: 49%;
    top: 60%; */
    left: static;
    right: static;
    top: static;
    bottom: static;
    margin: auto;
    margin-top: 200px;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
  }
</style>
<style scoped src="styles/views/index/index.css">

</style>
