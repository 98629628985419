<template>
  <div class="menuLeft">
    <div class="">
      <div class="menuLeftWrap">
        <span class="menuLeftList" v-for="item in storgaeListData" v-bind:key="item.id" @click="to">
          <span>{{ item.name }}</span>
          <span>¥{{ item.deliver_price }}元</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// import { seachLog } from 'network/api'
import { getAllStorage3 } from 'network/api'
export default {
  data () {
    return {
      storgaeListData: []
    }
  },
  created () {
    getAllStorage3({ key: this.$configList[window.location.host] }).then((res) => {
      if (res.code === 1) {
        this.storgaeListData = res.data
      }
    })
  },
  methods: {
    to () {
      this.$router.push('/orderAdmin/purchase')
    }
  }
}
</script>
<style>
  .menuLeft{
    height: 560px;
    width: 300px;
    background: rgba(0, 0, 0, .3);
    color: #fff;
    overflow-y: scroll;
  }
  .menuLeft::-webkit-scrollbar { width: 0 !important }
  .menuLeftWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menuLeftList{
    margin: 8px 0;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
</style>
